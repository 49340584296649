
* {
  color: #FFF;
  font-weight: bold;
}

body {
    background-image: url('./assets//assets/img/window.jpg');
    background-size: cover; /* Ensures the image covers the whole body */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    height: 100vh; /* Ensures the body height matches the viewport */
    margin: 0; /* Removes any default margin on the body */
}

.title {
  top: 38vh;
  right:10vw;
  position: absolute;
  font-size: 96px;
  text-align: right;
}

.text {
  font-size: 31px;
}